@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

.font-inter {
  font-family: 'Inter', sans-serif;

}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

.eAyARa {
  background-color: red;
}

/* For Input type Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Adjusting NavBar For small Screens */
@media screen and (max-width: 359px) {
  .smallNav {
    overflow-y: scroll;
    position: relative;
    height: 100%;
    z-index: 10;
    /* font-size: 10px; */
    margin-bottom: 10px;
    height: 91px;
    padding-bottom: 48px;
    top: 0px;
    border-bottom-width: 20px;
  }
  .smallNav2 {
    overflow: scroll;
    /* height: 100%; */
    z-index: 100;
    margin-bottom: 0;
  }
}

/* Animation for Slider In */
.slider-in {
  animation: slideMe 0.7s ease-in;
}

@keyframes slideMe {
  0% {
    transform: skewX(10deg) translateX(500px);
    opacity: 0;
  }
  60% {
    transform: translateX(0px);
  }

  62% {
    transform: skewX(0deg) translateX(30px);
  }
  70% {
    transform: skew(-20deg);
    opacity: 1;
  }
  80% {
    transform: skey(0deg) translate(0);
  }
  90% {
    transform: skew(-5deg);
  }
  100% {
    transform: skew(0deg);
  }
}

.slider-in2 {
  animation: slideMe2 0.3s ease-in-out;
}

@keyframes slideMe2 {
  0% {
    transform: translateX(500px);
  }

  70% {
    opacity: 1;
  }
}

.mobNav {
  overflow: hidden !important;
}

/* Pagination Style */
.pagination {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  padding-left: 20px;
  align-items: center;
}
.page-item {
  border: 0.5px solid #989898;
  padding: 1px 6px 1px 6px;
  font-size: 12px;
  align-items: center;
}
.page-link {
  /* color: white; */
}
.breakPoint {
  border: 0.5px solid #989898;
  padding: 4px 4px 2px 4px;
  font-size: 12px;
}
.activePage {
  border: 1px solid #00a78e;
  border-radius: 3px;
}

/* Date Customize */
input.date {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  width: auto;
}


::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 167, 142, 0.284); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #00A75e;  */
}



/* ------- I Bounce Animation -------------- */

.bounce {
  animation: bounce 1s infinite;
}

.bounce:hover {
  animation-play-state: paused;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* ---------------- Animation For quote Policy Tiles  Start Here --------------- */

.card {
  
 opacity: 0;
  transform: scale(0.5) translateY(50%);
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

@keyframes card-animation {
  0% {
    transform: translateY(50%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.card:nth-child(1) {
  animation-delay: 0.12s;
  animation-name: card-pop-up;
}

.card:nth-child(2) {
  animation-delay: 0.22s;
  animation-name: card-slide-in-left;
  
}

.card:nth-child(3) {
  animation-delay: 0.33s;
  animation-name: card-slide-in-right;
}

.card:nth-child(4) {
  animation-delay: 0.3s;
  animation-name: card-slide-in-left;
}

@keyframes card-pop-up {
  0% {
    transform: scale(0.5) translateY(50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes card-slide-in-left {
  0% {
    transform: scale(0.5) translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}

@keyframes card-slide-in-right {
  0% {
    transform: scale(0.5) translateX(100%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}
/* ---------------- Animation For quote Policy Tiles  End Here --------------- */

@media screen and (max-width: 500px) {

  .modal2 {
    display: block; /* Show the modal when the "show" class is added */
    animation: modal-entry1 0.45s;
  }
  
  @keyframes modal-entry1 {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media screen and (min-width: 500px) {

  .modal2 {
    display: block; /* Show the modal when the "show" class is added */
    animation: modal-entry1 0.45s;
  }
  
  @keyframes modal-entry1 {
    0% {
      transform: scale(0.8) translateY(-50%);
      opacity: 0.2;
    }
    100% {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }

}

/* -------- Animation For Details Window  */
.modal {
  display: block; /* Show the modal when the "show" class is added */
  animation: modal-entry 0.5s;
}

@keyframes modal-entry {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.modal5.active {
  height: 100vh;
}


/* Custom Checkbox  */

/* index.css */


.checkbox-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.checkbox-input {
  display: none;
}

.checkbox-custom {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #00A78E;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}

.checkbox-input:checked + .checkbox-custom::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 8px;
  border: solid #00A78E;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Optional: Adjust the checkbox size */
.checkbox-label.large .checkbox-custom {
  width: 24px;
  height: 24px;
}

.checkbox-label.large .checkbox-input:checked + .checkbox-custom::after {
  width: 10px;
  height: 16px;
  top: 6px;
  left: 8px;
}



@keyframes popUpAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.tick-animation{
  animation: popUpAnimation 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}



.dot-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: #2d6c34;
  border-radius: 50%;
  margin: 0 5px;
  animation: moveUpDown 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}



/* Toggle Button Animation */
.toggle-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-input {
  display: none;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

/* Change background color when toggled */
.toggle-input:checked + .toggle-slider {
  background-color: #00A78E;
}

/* Move slider to the right when toggled */
.toggle-input:checked + .toggle-slider:before {
  transform: translateX(26px);
}



/* ---------------------    Custom Checkbox Animation Start Here   -------------------------------------------- */

.custom-checkbox {
  position: relative;
  display: inline-block;
  top:3px;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox label {
  /* position: relative; */
  display: inline-block;
  width: 20px;
  height: 20px;
  /* background-color: rgba(44, 180, 160, 0.732); */
  border: 2px solid #cbc1c1;
  border-radius: 4px; /* Added rounded corners */
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.custom-checkbox label:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + label {
  /* background-color: #00A78E; */
  transform: scale(1.1);
  opacity: 0.8;
  
}

.custom-checkbox input:checked + label:after {
  display: block;
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #00A78E; /* Set the color of the tick mark */
  border-width: 0 2px 2px 0;
  animation: popUp 0.3s ease-in-out forwards;
}

@keyframes popUp {
  from {
    transform: rotate(45deg) scale(0);
  }
  to {
    transform: rotate(45deg) scale(1);
  }
}

.custom-checkbox label:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* ------------------------------------------------------------------------- */

/* ------------------------------------ Custom Radio Button --------------------- */

/* Use a different class name for the custom radio button styles */
.custom-radio {
  position: relative;
  display: inline-block;
  top:1px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio label {
  /* position: relative; */
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 2px solid #cbc1c1; /* Set border color to the default color */
  border-radius: 50%; /* Make it a circle */
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.custom-radio label:after {
  content: "";
  position: absolute;
  border: 2px solid #00A78E;
  display: none;
}

.custom-radio input:checked + label:after {
  display: block;
  content: "";
  position: absolute;
  left: 2.4px;
  top: 6.75px;
  width: 8.5px;
  height: 8.5px;
  background-color: #00A78E; /* Set the color of the dot */
  border-radius: 50%; /* Make it a circle */
  transform: scale(0);
  animation: popUp2 0.3s ease-in-out forwards;
}

@keyframes popUp2 {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.carIn {
  animation: popUp2 0.5s ease-in-out forwards;

}


/* Define the styles for the radio button container */
/* Define the styles for the radio button container */
/* Define the styles for the radio button container */
.radio-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 10px;
  gap: 6px;

}

/* Style the radio button dot */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

}

/* Style the checkmark (dot) */
.checkmark {
  height: 13px; /* Adjusted to about 9px radius */
  width: 13px; /* Adjusted to about 9px diameter */
  background-color: #fff; /* Default color when unchecked */
  border: 2px solid #cbc1c1; /* Border color */
  border-radius: 50%;
}

/* Change the dot color when the radio button is checked */

/* Change the dot color when the radio button is checked */
.radio-container input:checked + .checkmark {
  background-color: #00A78E; /* Change the color to blue when checked */
  border: 2px solid #cbc1c1;
  animation: popUp2 0.3s ease-in-out forwards;

}



/* ------------------------------------ */
.discount-container {
  /* display: flex; */
  /* justify-content: space-between;/ */
  /* margin: 20px; */
}

.discount-button {
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.discount-button:hover {
  background-color: #00A78E; /* Updated background color on hover */
  color: #fff; /* Updated text color on hover */
  transform: scale(1.1); /* Scale the button on hover */
}


/* -------------- Animation For Wait Payment Loader */

/* styles.css (or your global styles file) */
/* styles.css (or your global styles file) */
@keyframes bounce-in {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.animate-bounce-in {
  animation: bounce-in 0.5s ease-in-out;
}


@keyframes scale-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-scale-in {
  animation: scale-in 0.3s ease-in-out;
}


/* ------------------- Shake Effect For Wrong Input ---------- */

 

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }
  20%, 80% {
    transform: translateX(5px);
  }
  30%, 50%, 70% {
    transform: translateX(-3px);
  }
  40%, 60% {
    transform: translateX(3px);
  }
}

.animate-shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes rainAnimation {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(100vh);
  }
}

/* YourComponent.css */
@keyframes powderAnimation {
  from {
    transform: translateY(-100vh) rotate(0deg);
  }
  to {
    transform: translateY(100vh) rotate(360deg);
  }
}



/* ----------------- Animation for Quote Loading Screen ------------ */
.card-skelton {
  display: flex;
  width: 100%;
  height: 100%;
  color: #6abbaf2c;
}

.card-skelton .left-col {
  margin-right: 1rem;
}

.card-skelton .right-col {
  flex :1;
}




.toggle-button {
  width: 60px;
  height: 25px;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
}

.slider {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
}

.toggle-button.active .slider {
  transform: translateX(35px);
}



/* Additional styles for better appearance */

  /* Popup Animation */
  @keyframes popup {
    0% {
      transform: scale(0.5); /* Start at 50% scale */
      opacity: 0; /* Start with opacity 0 */
    }
    100% {
      transform: scale(1); /* End at 100% scale */
      opacity: 1; /* End with full opacity */
    }
  }

  /* Apply animation to the div */
  .popup-div {
    animation: popup 0.5s ease forwards; /* Apply the animation */
    position: relative; /* Ensure positioning */
  }

 