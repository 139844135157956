
/* -------- Animation For Details Window  */
.sec2Anim1 {
    display: block; /* Show the modal when the "show" class is added */
    animation: modal-entry 2.0s;
  }
  
  @keyframes modal-entry {
    from {
      opacity: 0;
      transform: translateY(-50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
.sec2Anim2 {
    display: block; /* Show the modal when the "show" class is added */
    animation: modal-entry2 2.0s;
  }
  
  @keyframes modal-entry2 {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
 /* ----------- Animation For Article ------------------------ */
.cls {
    opacity: 0;
}
 
 .article .card1 {
    animation: card2Anim  1s ease forwards;
  }
  
  .article .card2 {
    animation: card1Anim  1s ease forwards;
  }
  
  .article .card3 {
    animation: card2Anim 1s ease forwards;
  }
  
  @keyframes card1Anim {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes card2Anim {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes card3Anim {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ---------------- Our Partner Animation ------------------ */
  .popup {
   
    /* transform: translate(-50%, -50%); */
   
  
  
    animation: popup4 1s ease-in-out;
  }
  
  @keyframes popup4 {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }


  /* --------------- Faq section ANimation */



  .faq1 {
   
   
  
  
    animation: popup5 1s ease;
  }
  
  @keyframes popup5 {
    0% {
      transform:  translateX(0);
      opacity: 0;
    }
    100% {
      transform:  translateX(5%);
      opacity: 1;
    }
  }

  .faq2{
   
   
  
  
    animation: popup6 1s ease;
  }
  
  @keyframes popup6 {
    0% {
      transform:  translateX(0);
      opacity: 0;
    }
    100% {
      transform:  translateX(-10%);
      opacity: 1;
    }
  }